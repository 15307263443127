import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import CashAlert from "@/components/cash-income/CashAlert";
import PaymentAnnouncementForm from "@/components/cash-income/PaymentAnnouncementForm";
import ToastError from "@/components/error/ToastError";
import CashItemPlaceholder from "@/components/placeholder/cash/CashItemPlaceholder";
import PlaceholderLayout from "@/components/placeholder/PlaceholderLayout";
import IconDollarSign from "@/components/svg/dollar-sign";
import IconHouse from "@/components/svg/house";
import IconPaperclipSolid from "@/components/svg/paperclip-solid";
import AlertBox from "@/components/ui/AlertBox";
import BoxContainer from "@/components/ui/BoxContainer";
import Breadcrumb from "@/components/ui/Breadcrumb";
import Button from "@/components/ui/Button";
import CashItemDetail from "@/components/ui/CashItemDetail";
import FactorBox from "@/components/ui/FactorBox";
import NeedSupport from "@/components/ui/NeedSupport";
import Title from "@/components/ui/Title";
import PageTitle from "@/components/utility/PageTitle";
import useCash from "@/hooks/queries/useCash";
import { Cash } from "@/interfaces/dashboard/order/order";
import { formatNumber } from "@/utils";
import { cashStatus } from "@/utils/cases";

import OrderItemDetail from "../order/OrderItemDetail";

const CashItem = () => {
    const [cash, setCash] = useState<Cash | undefined>();

    const navigate = useNavigate();

    const { cashId } = useParams();
    const { search } = useLocation();
    const [searchParams] = useSearchParams(search);

    useEffect(() => {
        if (searchParams.get("error")?.length) {
            ToastError(new Error(searchParams.get("error") || ""));
        }
        if (searchParams.get("success")?.length) {
            toast.success(searchParams.get("success") || "");
        }
    }, [searchParams]);

    const { data: cashResponse, isError, refetch: refetchCash } = useCash(Number(cashId));

    useEffect(() => {
        if (cashResponse && cashResponse.cash) {
            setCash(cashResponse.cash);
        }
    }, [cashResponse]);

    useEffect(() => {
        let interval: number | undefined;
        if (cash?.status != "رد شده" && cash?.status != "انجام شده ") {
            interval = setInterval(() => {
                refetchCash();
            }, 10000);
        }

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isError) {
        ToastError(new Error("خطا در بارگزاری صفحه! لطفا مجددا تلاش کنید"));
        navigate("/panel");
    }

    return (
        <PlaceholderLayout>
            <PageTitle>نمایش نقد درآمد</PageTitle>
            <Breadcrumb
                sections={[
                    { link: "/panel", text: "صفحه اصلی", icon: IconHouse },
                    { link: "/panel/cash-income/list", text: "لیست نقدها" },
                    { link: "#", text: `نقد درآمد  ${cash?.id ?? "------"}#`, active: true },
                ]}
            />
            <div className={`flex w-full ${cash?.status == "رد شده" ? "justify-center" : ""} gap-8 max-md:flex-col`}>
                <BoxContainer
                    colorBox={false}
                    className={`${cash?.status == "رد شده" ? "max-md:flex-1 md:w-[550px]" : "flex-1"}`}
                >
                    <Title title="اطلاعات نقد در آمد" containerClasses="pb-4 -mx-5 -my-5" />
                    {cash ? (
                        <div className="-mx-5">
                            {cash?.cash_service && <CashItemDetail title={"درخواست نقد"} value={cash.cash_service} />}
                            {cash?.created_at && <CashItemDetail title={"تاریخ ثبت"} value={cash.created_at} />}
                            {cash?.status && <CashItemDetail title={"وضعیت"} value={cash.status} />}
                            {cash?.pay_date && (
                                <CashItemDetail title={"تاریخ پرداخت"} value={<span dir="ltr">{cash.pay_date}</span>} />
                            )}
                            {cash?.amount && (
                                <CashItemDetail title={`میزان(${cash.service})`} value={formatNumber(+cash.amount)} />
                            )}
                            {cash?.currency_name && <CashItemDetail title={"نوع ارز"} value={cash.currency_name} />}
                            {cash?.account_type && (
                                <CashItemDetail
                                    title={"نوع حساب"}
                                    value={cash.account_type == "personal" ? "شخصی" : "شرکتی"}
                                />
                            )}
                            {cash?.register_avalp_to_site ? (
                                <CashItemDetail
                                    title={"اول پرداخت در سایت مبدا حساب دارد"}
                                    value={cash.register_avalp_to_site == 1 ? "بله" : "خیر"}
                                />
                            ) : (
                                ""
                            )}
                            {cash?.company_address ? (
                                <CashItemDetail title={"آدرس سایت واریز کننده"} value={cash.company_address} />
                            ) : (
                                ""
                            )}

                            <div className="px-6 pt-6">
                                <NeedSupport
                                    title="آیا برای این نقد به پشتیبانی نیاز دارید؟"
                                    ticketLink={`/panel/ticket/new#support`}
                                    supportText={`با سلام وقت بخیر</br>هر گونه راهنمایی در رابطه با نقد ${cash?.id} نیاز داشته باشید، در خدمتتون هستم.`}
                                />
                            </div>
                        </div>
                    ) : (
                        <CashItemPlaceholder />
                    )}

                    {cash?.receive_amount_message && (
                        <AlertBox
                            type="warning"
                            className="mt-8"
                            title="توجه کنید"
                            description={cash.receive_amount_message}
                        />
                    )}

                    {cash?.admin_message && (
                        <BoxContainer className="mt-8" colorBox={false}>
                            <Title title="توضیحات کارشناس" containerClasses="pb-4 -mx-5 -my-5" />
                            <div className="flex flex-col gap-4 pt-4">{cash.admin_message}</div>
                        </BoxContainer>
                    )}
                    {cash?.admin_attachments && cash?.admin_attachments.length ? (
                        <BoxContainer className="mt-8" colorBox={false}>
                            <Title title="فایل‌های پیوست شده" containerClasses="pb-4 -mx-5 -my-5" />

                            <div className="flex flex-col gap-4 pt-4">
                                {cash?.admin_attachments.map((attachment, index) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="nofollow noreferrer"
                                            key={index}
                                            className="group flex items-center gap-2 text-primary hover:text-primary"
                                            href={attachment.link}
                                        >
                                            <IconPaperclipSolid className="-mt-1 w-4 fill-primary group-hover:fill-primary" />
                                            {attachment.file_name}
                                        </a>
                                    );
                                })}
                            </div>
                        </BoxContainer>
                    ) : (
                        ""
                    )}
                </BoxContainer>

                {cash?.status == "رد شده" ? (
                    <></>
                ) : (
                    <div className="flex-1">
                        <FactorBox
                            icon={IconDollarSign}
                            withoutBottom={
                                cash?.status == "در انتظار پرداخت" || (cash?.status == "اتوماتیک" && cash?.api == "1")
                            }
                            title={
                                cash?.status
                                    ? cash.status == "در انتظار پردازش"
                                        ? "در انتظار بررسی و صدور فاکتور نهایی"
                                        : cash?.status
                                    : ""
                            }
                            color={cashStatus(cash?.status).color}
                        >
                            {cash?.status && (
                                <CashAlert
                                    serviceType={cash.cash_service}
                                    cashType={cash.cash_type}
                                    status={cash.status}
                                />
                            )}

                            {cash?.status == "در انتظار صدور فاکتور" ? (
                                <></>
                            ) : (
                                <>
                                    {cash?.status == "در انتظار پرداخت" ? (
                                        <></>
                                    ) : (
                                        <BoxContainer colorBox={false}>
                                            <div className="-mx-5 -my-5">
                                                <div className="w-full p-6 text-center text-[1.42rem] font-bold text-text-main">
                                                    فاکتور درخواست شماره&nbsp;
                                                    <span dir="ltr">#{cash?.id}</span>
                                                </div>
                                                <div className="space-y-4 px-4 py-4">
                                                    <OrderItemDetail title={"نقد درآمد ارزی"} value={cash?.cash_service} />
                                                    <OrderItemDetail
                                                        title={"میزان ارز"}
                                                        value={formatNumber(Number(cash?.amount))}
                                                    />
                                                    <OrderItemDetail title={"فی"} value={formatNumber(Number(cash?.fee))} />
                                                    <OrderItemDetail
                                                        title={"میزان خالص دریافتی"}
                                                        value={formatNumber(Number(cash?.receive_amount))}
                                                    />
                                                    <OrderItemDetail title={"نوع ارز"} value={cash?.currency_name} />
                                                    <OrderItemDetail
                                                        title={"نرخ خرید"}
                                                        value={
                                                            cash?.currency_price ? (
                                                                formatNumber(Number(cash?.currency_price)) + " تومان"
                                                            ) : (
                                                                <Skeleton width={120} />
                                                            )
                                                        }
                                                    />
                                                    {cash?.is_immediate ? (
                                                        <OrderItemDetail
                                                            title={"کارمزد تسویه آنی"}
                                                            value={
                                                                cash?.immediate_wage ? (
                                                                    formatNumber(Number(cash?.immediate_wage)) +
                                                                    " تومان"
                                                                ) : (
                                                                    <Skeleton width={120} />
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className="space-y-4 px-4 pb-6 pt-4">
                                                    <div className="flex w-full items-center justify-between">
                                                        <div className="font-semibold text-text-main">
                                                            {cash?.calc_total_title ?? (
                                                                <Skeleton width={120} height={15} />
                                                            )}
                                                        </div>
                                                        <div className="text-[1.42rem] text-gold">
                                                            {cash?.calc_total ? (
                                                                <>
                                                                    {formatNumber(Number(cash?.calc_total))}
                                                                    <span className="text-[1rem]">تومان</span>
                                                                </>
                                                            ) : (
                                                                <Skeleton width={120} height={15} />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BoxContainer>
                                    )}

                                    {cash?.status == "در انتظار پرداخت" ? (
                                        <PaymentAnnouncementForm
                                            cashId={cash.id}
                                            isDigital={cash?.isDigital}
                                            service={cash?.service}
                                            pcode={cash?.pcode}
                                            link={cash?.invoice_link || ""}
                                            wallet={cash?.admin_wallet}
                                            linkTitle={cash?.invoice_link_message}
                                            time={cash?.expired_in}
                                            date={cash?.expired_at}
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {cash?.status == "اتوماتیک" && cash?.api == "1" && (
                                        <>
                                            <AlertBox
                                                type="warning"
                                                description={
                                                    "شما با فشردن دکمه پرداخت وجه مستقیما به درگاه پرفکت مانی برای عملیات انتقال وجه انتقال داده می‌شوید"
                                                }
                                            />
                                            <Button type="button" onClick={() => (location.href = `${cash.api_link}`)}>
                                                پرداخت وجه
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </FactorBox>
                    </div>
                )}
            </div>
        </PlaceholderLayout>
    );
};
export default CashItem;
