import { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { IMMEDIATE_WITHDRAW_COST } from "@/constants/common";
import useCashCalculation from "@/hooks/queries/useCashCalculation";
import useCashCreate from "@/hooks/queries/useCashCreate";
import useCashSubmit from "@/hooks/queries/useCashSubmit";
import useLocalStorage from "@/hooks/useLocalStorage";
import {
    CashCalculationRequestType,
    CashCreateService,
    Currency,
    ServicesListType,
} from "@/interfaces/dashboard/order/order";
import { formatNumber, isEmpty } from "@/utils";

import ToastError from "../error/ToastError";
import CryptoPerfectCashPlaceholder from "../placeholder/cash/CryptoPerfectCashPlaceholder";
import PaypalCashPlaceholder from "../placeholder/cash/PaypalCashPlaceholder";
import PerfectmoneyVoucherCashPlaceholder from "../placeholder/cash/PerfectmoneyVoucherCashPlaceholder";
import BoxContainer from "../ui/BoxContainer";
import Title from "../ui/Title";
import PageTitle from "../utility/PageTitle";
import CryptoPerfectCashForm from "./forms/CryptoPerfectCashForm";
import PaypalWiseCashForm from "./forms/PaypalWiseCashForm";
import PerfectmoneyVoucherCashForm from "./forms/PerfectmoneyVoucherCashForm";
import SwiftCashForm from "./forms/SwiftCashForm";
import WebmoneyCashForm from "./forms/WebmoneyCashForm";

type CashFormProps = {
    serviceName: string;
    onDataAvailable: (data: CashCreateService) => void;
    onCurrencyChange: (currencyId: number) => void;
};

const CashForm: React.FC<CashFormProps> = ({ serviceName, onDataAvailable, onCurrencyChange }) => {
    const [allServices] = useLocalStorage<ServicesListType | undefined>("all_services", undefined);
    const [service, setService] = useState<CashCreateService | undefined>();

    const [calculatedPrice, setCalculatedPrice] = useState<Currency>();
    const [calculationData, setCalculationData] = useState<{
        serviceName: string;
        amount: number;
        currency: number | string;
    }>();

    const { data, refetch: refetchCashInstruction, isLoading } = useCashCreate(serviceName);

    useEffect(() => {
        if (data) {
            setService(data.service);
        }
    }, [data]);

    const {
        data: cashPrices,
        refetch: refetchCashPrices,
        isRefetching: isCashPricesLoading,
    } = useCashCalculation(calculationData);

    const delayTimer = useRef<number | null>(null);

    useEffect(() => {
        if (delayTimer.current) {
            clearTimeout(delayTimer.current);
        }

        delayTimer.current = setTimeout(() => {
            refetchCashPrices();
        }, 500);
    }, [calculationData, refetchCashPrices]);

    useEffect(() => {
        if (cashPrices) {
            setCalculatedPrice(cashPrices);
        }
    }, [cashPrices]);

    useEffect(() => {
        if (serviceName) {
            refetchCashInstruction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceName]);

    useEffect(() => {
        if (service) {
            onDataAvailable(service);
        }
    }, [service, onDataAvailable]);

    const onCalculation = (data: CashCalculationRequestType) => {
        setCalculationData(data);
    };

    const { mutate: submitCash, isLoading: isSubmitting } = useCashSubmit();

    const onFormSubmit = (data: unknown) => {
        if (!calculatedPrice) {
            ToastError({ message: "ابتدا فیلدهای مورد نیاز را وارد کنید" });
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (data.is_immediate && calculatedPrice.total <= IMMEDIATE_WITHDRAW_COST + 1000) {
            ToastError({
                message: `حداقل میزان برای تسویه آنی ${formatNumber(IMMEDIATE_WITHDRAW_COST + 1000)} تومان است`,
            });
            return;
        }

        submitCash(data);
    };

    const cashForm = useMemo(() => {
        if (!service && !isEmpty(allServices)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const serviceData = allServices?.cashServices[serviceName];

            if (serviceData) {
                if (
                    serviceData?.form_file.endsWith("paypal.user") ||
                    serviceData?.form_file.endsWith("webmoney.user") ||
                    serviceData?.form_file.endsWith("wise.user")
                ) {
                    return <PaypalCashPlaceholder isPaypal={serviceData?.form_file.endsWith("paypal.user")} />;
                } else if (
                    serviceData?.form_file.endsWith("perfectmoney.user") ||
                    serviceData?.form_file.endsWith("eth.user") ||
                    serviceData?.form_file.endsWith("btc.user") ||
                    serviceData?.form_file.endsWith("tether.user") ||
                    serviceData?.form_file.endsWith("broker_cashincome.user")
                ) {
                    return <CryptoPerfectCashPlaceholder isTether={serviceData?.form_file.endsWith("tether.user")} />;
                } else if (serviceData?.form_file.endsWith("perfectmoney_voucher.user")) {
                    return <PerfectmoneyVoucherCashPlaceholder />;
                } else if (serviceData?.form_file.endsWith("swift.user")) {
                    return <PerfectmoneyVoucherCashPlaceholder />;
                }
            }
        }

        if (service) {
            const formFile = service.form_file;
            if (formFile.endsWith("paypal.user") || formFile.endsWith("wise.user")) {
                return (
                    <PaypalWiseCashForm
                        isLoading={isSubmitting}
                        isCashPricesLoading={isCashPricesLoading}
                        calculatedPrice={calculatedPrice}
                        instructions={data}
                        onCalculation={onCalculation}
                        onFormSubmit={onFormSubmit}
                        onCurrencyChange={onCurrencyChange}
                    />
                );
            } else if (formFile.endsWith("webmoney.user")) {
                return (
                    <WebmoneyCashForm
                        isLoading={isSubmitting}
                        isCashPricesLoading={isCashPricesLoading}
                        calculatedPrice={calculatedPrice}
                        instructions={data}
                        onCalculation={onCalculation}
                        onFormSubmit={onFormSubmit}
                    />
                );
            } else if (
                formFile.endsWith("perfectmoney.user") ||
                formFile.endsWith("eth.user") ||
                formFile.endsWith("btc.user") ||
                formFile.endsWith("tether.user") ||
                formFile.endsWith("broker_cashincome.user")
            ) {
                return (
                    <CryptoPerfectCashForm
                        isLoading={isSubmitting}
                        isCashPricesLoading={isCashPricesLoading}
                        calculatedPrice={calculatedPrice}
                        instructions={data}
                        onCalculation={onCalculation}
                        onFormSubmit={onFormSubmit}
                    />
                );
            } else if (formFile.endsWith("perfectmoney_voucher.user")) {
                return (
                    <PerfectmoneyVoucherCashForm
                        isLoading={isSubmitting}
                        onFormSubmit={onFormSubmit}
                        instructions={data}
                    />
                );
            } else if (formFile.endsWith("swift.user")) {
                return (
                    <SwiftCashForm
                        isLoading={isSubmitting}
                        isCashPricesLoading={isCashPricesLoading}
                        calculatedPrice={calculatedPrice}
                        instructions={data}
                        onCalculation={onCalculation}
                        onFormSubmit={onFormSubmit}
                        onCurrencyChange={onCurrencyChange}
                    />
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allServices, calculatedPrice, data, isCashPricesLoading, isSubmitting, onCurrencyChange, service, serviceName]);

    return (
        <>
            <PageTitle>فروش ارز به اول پرداخت</PageTitle>
            <BoxContainer colorBox={false} className="z-40 w-full xs:overflow-visible">
                <Title
                    title={
                        isLoading ? (
                            <Skeleton width={280} />
                        ) : serviceName == "BROKER_CASHINCOME" ? (
                            service?.name
                        ) : (
                            `فروش ${service?.name ?? ""} به اول پرداخت`
                        )
                    }
                    containerClasses="-m-5"
                    className="flex h-full flex-col gap-6 p-0"
                >
                    {cashForm}
                </Title>
            </BoxContainer>
        </>
    );
};

export default CashForm;
