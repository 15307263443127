import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";

import packageJson from "../package.json";

function withClearCache<T>(Component: React.ComponentType<T>) {
    const ClearCacheComponent = (props: T) => {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
        const { data, isError } = useQuery({
            queryFn: () => {
                return axios.get("/meta.json?t=" + new Date().getTime());
            },
            select: (response) => {
                return response?.data;
            },
            queryKey: ["versionData"],
            refetchInterval: 10 * 60 * 1000,
        });

        useEffect(() => {
            if (data) {
                const latestVersionDate = data.buildDate;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                const currentVersionDate = packageJson.buildDate;

                console.log("latest : ", latestVersionDate);
                console.log("current: ", currentVersionDate);

                if (latestVersionDate > currentVersionDate) {
                    setIsLatestBuildDate(false);
                    refreshCacheAndReload();
                } else {
                    setIsLatestBuildDate(true);
                }
            } else if (isError) {
                setIsLatestBuildDate(true);
            }
        }, [data, isError]);

        const refreshCacheAndReload = () => {
            if (window?.caches) {
                // Service worker cache should be cleared with window.caches.delete()
                window.caches.keys().then((names) => {
                    for (const name of names) {
                        window.caches.delete(name);
                    }
                });
            }
            // delete browser cache and hard reload
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.location.reload(true);
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return <>{isLatestBuildDate ? <Component {...props} /> : null}</>;
    };

    return ClearCacheComponent;
}

export default withClearCache;
