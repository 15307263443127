import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import FileInput from "@/components/ui/FileInput";
import Input from "@/components/ui/Input";
import Textarea from "@/components/ui/Textarea";
import { NUMBER_INPUT_FLOATING_POINT } from "@/constants/common";
import { CalculationRequestType, ServiceInstructionType, SubmitOrderType } from "@/interfaces/dashboard/order/order";
import { objectToArray } from "@/utils";

type OrderValidationSchema = {
    serviceId: number;
    amount: number | "";
    wallet: string;
    user_description: string | null | undefined;
    attachments?: Record<string, File>;
};

type SubmitOrderRequest = SubmitOrderType & {
    amount: number | "";
    meta: {
        wallet?: string;
    };
};

type ServiceInstruction = ServiceInstructionType & {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: SubmitOrderRequest, serviceId: number) => void;
};

const CryptoChargeForm: React.FC<ServiceInstruction> = ({ instructions, onCalculation, onFormSubmit }) => {
    const [queryString] = useSearchParams();

    const walletConstant = useMemo(() => {
        return instructions.constants?.find((item) => {
            return item.key == "meta.wallet";
        });
    }, [instructions.constants]);

    const { control, handleSubmit, resetField } = useForm<OrderValidationSchema>({
        defaultValues: {
            amount: queryString.get("amount") ? Number(queryString.get("amount")) : "",
            wallet: walletConstant ? walletConstant.value : "",
            user_description: "",
        },
    });

    const [watchedAmount] = useWatch({
        control: control,
        name: ["amount"],
        defaultValue: {
            amount: 0,
        },
    });

    useEffect(() => {
        if (Number(watchedAmount) && +watchedAmount >= 0) {
            onCalculation({
                amount: +watchedAmount,
                serviceId: instructions.id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchedAmount]);

    useEffect(() => {
        onCalculation({
            amount: 0,
            serviceId: instructions.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitHandler: SubmitHandler<OrderValidationSchema> = (data) => {
        onFormSubmit(
            {
                amount: data.amount,
                meta: {
                    wallet: data.wallet,
                },
                user_description: data.user_description,
                attachments: data.attachments ? objectToArray(data.attachments) : undefined,
            },
            instructions.id,
        );
    };

    return (
        <form id="order-form" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-4">
                <Input
                    ltr
                    containerClassName="w-full"
                    variant="fill"
                    topTitle="میزان"
                    required
                    inputMode="decimal"
                    type="number"
                    min={0}
                    step={NUMBER_INPUT_FLOATING_POINT}
                    placeholder="میزان"
                    control={control}
                    name="amount"
                    rules={{
                        required: "میزان الزامی است",
                    }}
                />

                <Input
                    containerClassName="w-full"
                    variant="fill"
                    topTitle={`کیف پول ${instructions.currency[0]?.name}`}
                    required
                    type={walletConstant ? "hidden" : "text"}
                    readOnly={walletConstant ? true : false}
                    inputMode="text"
                    placeholder={`کیف پول ${instructions.currency[0]?.name}`}
                    ltr
                    control={control}
                    name="wallet"
                    rules={{
                        required: `کیف پول ${instructions.currency[0]?.name}`,
                    }}
                />

                <Textarea
                    name="user_description"
                    control={control}
                    label="توضیحات"
                    placeholder="توضیحات خود را اینجا بنویسید"
                />
                <FileInput control={control} name="attachments" onReset={resetField} repeater />
            </div>
        </form>
    );
};
export default CryptoChargeForm;
