import "./App.css";

import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useRoutes, useSearchParams } from "react-router-dom";

import useLoadSettings from "@/hooks/useLoadSettings";

import withClearCache from "./ClearCache";
import ErrorPage from "./pages/dashboard/error/ErrorPage";
import NotFound from "./pages/dashboard/error/NotFound";
import routes from "./routes/Index";
import { Context, ContextType } from "./store/Context-store";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    if (import.meta.env.VITE_MODE_ENV === "production" || import.meta.env.VITE_MODE_ENV === "stage") {
        return <ClearCacheComponent />;
    } else {
        return <MainApp />;
    }
}

function MainApp() {
    useLoadSettings();

    const [params] = useSearchParams();

    const router = useRoutes(routes);

    const { error } = useContext(Context) as ContextType;

    const { hash, pathname, search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Disable auto zoom on input focus on iPhone smartphones
        if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
            document
                ?.querySelector("[name=viewport]")
                ?.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
        }
    }, []);

    useEffect(() => {
        // Add Referral code to cookies and redirect to landing page
        if (params.has("ref") || params.has("brokers")) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            Cookies.set("referral", params.get("ref") || params.get("brokers"), {
                path: "/",
                sameSite: "lax",
                secure: true,
                expires: 7,
            });

            location.href = import.meta.env.VITE_BASE_URL;
        }
    }, [params]);

    // Remove #Modal from addressbar if it's first render
    useEffect(() => {
        if (hash === "#modal") {
            navigate(`${pathname}${search}`, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (
        error &&
        ((error?.status &&
            error?.status !== 409 &&
            error?.status !== 410 &&
            error?.status !== 422 &&
            error?.status !== 401 &&
            error?.status !== 500) ||
            (error?.response &&
                error?.response?.status !== 409 &&
                error?.response?.status !== 410 &&
                error?.response?.status !== 422 &&
                error?.response?.status !== 401 &&
                error?.response?.status !== 500))
    ) {
        return <ErrorPage />;
    }

    return <>{router || <NotFound />}</>;
}

export default App;
